<template>
  <div>
    <div class="heng">
      <img src=".././assets/digitizationimg/banner.png" style="width:100%;background-repeat: no-repeat;
       background-position: center;
       background-size: cover;">
      <div class="title">
        <h3>数字化咨询</h3>
        <div class="info">在数字经济，数字中国背景下，面向企业和政府提供的战略转型、升级
          咨询与数字化系统设计服务。其主导思想是以新一代技术为支撑，创新
          工作模式，实现业务突破，完成信息化从成本中心向核心价值中心的转变。</div>
        <div class="title_btn">
          <button @click="pageHref" style="cursor:pointer;">联系我们</button><button @click="viewMore"
            style="cursor:pointer;">查看更多</button>
        </div>
      </div>
    </div>

    <div class="capacity">
      <div>
        <div style="display: flex;justify-content: center;">
          <p>金烽数字化咨询</p>
        </div>
        <div class="golden">
          <div style="display: flex;">
            <div class="hairPiece">
              <div class="title">战略咨询</div>
              <div class="tubiao"></div>
              <div style="display: flex;">
                <div  class="margin_ri">
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">战略执行体系评估</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">行业与竞争格局分析</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">发展战略与竞争力设计</div>
                  </div>
                </div>
                <div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">商业模式与增长路径设计</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">市场规划与竞争策略设计</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">产品与解决方案路标规划</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hairPiece">
              <div class="title">运营管理</div>
              <div class="tubiao"></div>
              <div style="display: flex;">
                <div  class="margin_ri">
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">运营战略与目标体系</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">需求管理体系建立</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">运营工具库建设</div>
                  </div>
                </div>
                <div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">业务组合设计</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">客户满意与忠诚体系</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">服务运营体系的构建</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hairPiece">
              <div class="title">流程管理体系</div>
              <div class="tubiao"></div>
              <div style="display: flex;">
                <div  class="margin_ri">
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">企业价值链设计</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">流程设计与优化</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">流程内控体系建设</div>
                  </div>
                </div>
                <div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">流程架构规划</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">流程管理组织建设</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">流程绩效体系建设</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex;">
            <div class="hairPiece">
              <div class="title">数字化规划</div>
              <div class="tubiao"></div>
              <div style="display: flex;">
                <div  class="margin_ri">
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">应用现状调研</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">业务蓝图设计</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">标准化清单与数据定义</div>
                  </div>
                </div>
                <div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">系统架构规划</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">建设项目实施监理</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">应用效果输出</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hairPiece">
              <div class="title">数字化运营</div>
              <div class="tubiao"></div>
              <div style="display: flex;">
                <div  class="margin_ri">
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">数据在线化诊断分析</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">商业及运营目标</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">标准化流程与指标定义</div>
                  </div>
                </div>
                <div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">运营策略规划</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">运营模型工具</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">运营增长能力</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="hairPiece">
              <div class="title">数据治理</div>
              <div class="tubiao"></div>
              <div style="display: flex;">
                <div  class="margin_ri">
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">企业数据分布地图分析</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">全业务数据统一标准</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">数据质量校验分析</div>
                  </div>
                </div>
                <div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">数据处理能力提升</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">数据合理分级授权</div>
                  </div>
                  <div class="column_Cls">
                    <div class="yuandian"></div>
                    <div class="content_size">数据加密脱敏处理</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <img src=".././assets/digitizationimg/digimgthere.png">
          <img src=".././assets/digitizationimg/digimgfour.png">
          <img src=".././assets/digitizationimg/digimgfive.png">
          <img src=".././assets/digitizationimg/digimgsix.png">
          <img src=".././assets/digitizationimg/digimgseven.png">
          <img src=".././assets/digitizationimg/digimgeight.png" > -->
        </div>
      </div>
    </div>

    <div class="schemw">
      <div class="schemw_content">
        <h4>数字化解决方案</h4>
        <img src="../assets/digitizationimg/nav.png" class="schemw_nav">
        <div class="schemw_list">
          <img class="img" v-for="i in 6" :key="i" :src="require('../assets/digitizationimg/' + i + '.png')">
          <img src="../assets/digitizationimg/left.png" class="schemw_pic left">
          <img src="../assets/digitizationimg/right.png" class="schemw_pic right">
        </div>
        <button class="schemw_btn" @click="pageHref" style="cursor:pointer;">获取更多解决方案></button>
      </div>
    </div>

    <div class="number">
      <div class="number_title">
        <h4>一站式数字孪生解决方案</h4>
        <p>需求调研-方案确定-三维场景研发-数据采集与汇聚-前后端联合调测-部署上线</p>
      </div>
      <div class="number_content">
        <div class="number_left">
          <div>数字通信</div>
          <div>数字城市</div>
          <div>数字政务</div>
        </div>
        <div class="number_right">
          <img src="../assets/digitizationimg/number.png">
        </div>
      </div>
    </div>

    <div class="surveyor">
      <p>标杆客户</p>
      <img src="../assets/digitizationimg/logo.png">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgData: [
        {
          title: '战略咨询',
          list: [
            '战略执行体系评估',
            '商业模式与增长路径设计',
            '行业与竞争格局分析',
            '市场规划与竞争策略设计',
            '发展战略与竞争力设计',
            '产品与解决方案路标规划'
          ],
          url: '.././assets/digitizationimg/digimgthere.png'
        },
        {
          title: '战略咨询',
          list: [
            '战略执行体系评估',
            '商业模式与增长路径设计',
            '行业与竞争格局分析',
            '市场规划与竞争策略设计',
            '发展战略与竞争力设计',
            '产品与解决方案路标规划'
          ],
          url: '.././assets/digitizationimg/digimgthere.png'
        },
        {
          title: '战略咨询',
          list: [
            '战略执行体系评估',
            '商业模式与增长路径设计',
            '行业与竞争格局分析',
            '市场规划与竞争策略设计',
            '发展战略与竞争力设计',
            '产品与解决方案路标规划'
          ],
          url: '.././assets/digitizationimg/digimgthere.png'
        },
        {
          title: '战略咨询',
          list: [
            '战略执行体系评估',
            '商业模式与增长路径设计',
            '行业与竞争格局分析',
            '市场规划与竞争策略设计',
            '发展战略与竞争力设计',
            '产品与解决方案路标规划'
          ],
          url: '.././assets/digitizationimg/digimgthere.png'
        },
        {
          title: '战略咨询',
          list: [
            '战略执行体系评估',
            '商业模式与增长路径设计',
            '行业与竞争格局分析',
            '市场规划与竞争策略设计',
            '发展战略与竞争力设计',
            '产品与解决方案路标规划'
          ],
          url: '.././assets/digitizationimg/digimgthere.png'
        },
        {
          title: '战略咨询',
          list: [
            '战略执行体系评估',
            '商业模式与增长路径设计',
            '行业与竞争格局分析',
            '市场规划与竞争策略设计',
            '发展战略与竞争力设计',
            '产品与解决方案路标规划'
          ],
          url: '.././assets/digitizationimg/digimgthere.png'
        }
      ]
    }
  },
  methods: {
    pageHref() {
      this.$router.push({
        path: '/tech/about',
        query: {
          vs: 1
        }
      })
    },
    viewMore() {
      this.$router.push('/tech/about')
    }
  }
}
</script>

<style lang="scss" scoped>
.heng {
  position: relative;
  font-size: 0;
  display: flex;
  justify-content: center;

  .title {
    position: absolute;
    top: 175px;
    width: 1396px;

    h3 {
      font-size: 71px;
      color: #fff;
    }

    .info {
      width: 600px;
      font-size: 18px;
      color: #D0D4E1;
      margin-bottom: 40px;
    }

    .title_btn {
      button:first-child {
        width: 180px;
        height: 50px;
        background: #FF892E;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #FF892E;
        color: #fff;
        font-size: 20px;
      }

      button:last-child {
        width: 110px;
        height: 50px;
        background: rgba(255, 137, 46, 0);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #FFFFFF;
        color: #fff;
        font-size: 20px;
        margin-left: 10px;
      }
    }
  }
}

.business {
  position: relative;
  height: 1042px;

  .bustt {
    height: 1042px;
    width: 100%;
  }

  .resistance {
    width: 100%;
    position: absolute;
    top: -32px;
    display: flex;
    justify-content: center;

    img {
      width: 1396px;
    }
  }
}

.capacity {
  width: 100%;
  height: 752px;
  display: flex;
  justify-content: center;
  padding-top: 35px;
  background: url(../assets/digitizationimg/bg1.png) no-repeat;
  background-size: cover;

  p {
    font-size: 42px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 55px;
  }

  .golden {
    width: 1428px;
    // display: flex;
    .hairPiece {
      background-image: url('.././assets/digitizationimg/zixun.png');
      width: 450px;
      height: 232px;
      padding-left: 28px;
      padding-top: 47px;
      margin: 0 13px 34px 14px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
       .content_size{   
         font-weight: 400;
          color: #475272;
          font-size: 16px;
       }
      .title {
        font-size: 24px;
        color: #000000;
        font-weight: bold;
      }

      .tubiao {
        width: 28px;
        height: 5px;
        background: #FF892E;
        margin-bottom: 34px;
        margin-top: 2px;
        margin-left: 2px;
      }

      .yuandian {
        width: 6px;
        height: 6px;
        background: #FF892E;
        border-radius: 5px;
        margin-right: 5px;
      }

      .margin_ri{
        margin-right: 50px;
      }
      .column_Cls {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }
    }
  }


}

.schemw {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1060px;
  background: url(../assets/digitizationimg/bg.png) no-repeat;
  background-size: cover;

  .schemw_content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 1400px;

    h4 {
      font-size: 42px;
      font-weight: bold;
      color: #000000;
    }

    .schemw_nav {
      width: 100%;
    }

    .schemw_list {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 40px;

      .img {
        width: 449px;
      }

      .schemw_pic {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.left {
          left: -50px;
        }

        &.right {
          right: -50px;
        }
      }
    }

    .schemw_btn {
      width: 266px;
      height: 50px;
      background: #FF892E;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #FF892E;
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}

.number {
  width: 1400px;
  margin: 160px auto 0;

  .number_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h4 {
      height: 55px;
      font-size: 42px;
      font-weight: bold;
      color: #000000;
    }

    p {
      font-size: 20px;
    }
  }

  .number_content {
    display: flex;
    margin-top: 40px;
    gap: 25px;

    .number_left {
      width: 211px;
      height: 584px;
      padding-top: 15px;
      border-right: 1px solid #D0D4E1;

      div {
        line-height: 106px;
        text-align: center;
        font-size: 20px;
        color: #475272;

        &:first-child {
          background: #D4E3FF;
        }
      }

    }

    .number_right {
      height: 584px;
      padding-top: 15px;

      img {
        height: 100%;
      }
    }

  }
}

.surveyor {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 66px;

  p {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-bottom: 42px;
  }

  img {
    width: 1173px;
  }

}</style>
